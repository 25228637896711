// React
import { render } from 'react-dom'
// 3rd Party - LogRocket
import LogRocket from 'logrocket'
// 3rd Party - Sentry
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
// DEPRECATE : MainStem - API
import { apiStatusCheck } from '../src/api/statusCheck/index'
// Local - App
import Layout from 'layout'

// Check to see if the app is running in development mode
const isDev = location.hostname === 'localhost' || location.hostname === '127.0.0.1'

if (!isDev) {
  // Initialize LogRocket
  LogRocket.init(import.meta.env.VITE_REACT_APP_LOGROCKET_SUPPLER_CORE_APP_ID ?? '')

  // Check MainStem API Status
  apiStatusCheck().then((apiResponse) => {
    if (apiResponse.wasSuccessful) {
      Sentry.init({
        dsn: import.meta.env.VITE_REACT_APP_SENTRY_SUPPLIER_CORE_DSN,
        integrations: [new BrowserTracing()],
        tracesSampleRate: 1.0,
        environment: apiResponse.environment
      })
    }
  })

  // Set session URL for use later
  LogRocket.getSessionURL((sessionURL) => {
    Sentry.setContext('session', {
      url: sessionURL
    })
    window.localStorage.setItem('logrocket_session_url', sessionURL)
  })
}

render(<Layout />, document.getElementById('root'))
