import axios from 'axios'
import * as Sentry from '@sentry/react'

const enterpriseToken = window.localStorage.getItem('mainstem_user_token')

// LogRocket Session URL
const sessionURL = window.localStorage.getItem('logrocket_session_url')

export const baseURL = import.meta.env.VITE_REACT_APP_BASE_API_URL || ""
export const apiSupplierURL = import.meta.env.VITE_REACT_APP_SUPPLIER_API_URL

export const apiURL = '/api/supplier/'

export const headers = {
  Authorization: `Bearer ${enterpriseToken || ''}`,
  'X-SessionURL': sessionURL,
  'X-Platform': 'Supplier Core'
}

function logUnsuccessfulResponses(response: any) {
  if (response.data && !response.data.wasSuccessful) {
    const msg =
      response.data.message ||
      response.data.error ||
      'API Response Unsuccessful'
    Sentry.captureException(new Error(msg), {
      contexts: {
        response: response.data
      }
    })
  }
  return response
}

// Set Axios defaults
const HTTP = axios.create({
  baseURL: baseURL + apiURL,
  headers: headers
})
HTTP.interceptors.response.use(logUnsuccessfulResponses)

const HTTPBase = axios.create({
  baseURL: baseURL,
  headers: headers
})
HTTPBase.interceptors.response.use(logUnsuccessfulResponses)

export const HTTPFile = () => {
  return axios.create({
    baseURL: baseURL + apiURL,
    headers: headers
  })
}

export { HTTP, HTTPBase }
