// 3rd Party - Sentry
import * as Sentry from "@sentry/react";
// 3rd Party - LogRocket
import LogRocket from 'logrocket'
// Global - Config
import { HTTP } from 'config'
// API - Response
import { APIAuthCurrentUserAPIResponse } from './response'

/** Required to make pendo work in TypeScript */
declare global {
  interface Window {
    pendo: any
  }
}

export async function apiAuthCurrentUser() {
  let apiResponse = {} as APIAuthCurrentUserAPIResponse

  await HTTP.get('auth/currentUser').then(
    (res) => {
      if (res.status === 200 && res.data) {
        apiResponse = res.data        
        try {
          // Pendo Intilization
          window.pendo.initialize({
            visitor: {
              id: res.data.uuid,
              email: res.data.username,
              full_name: res.data.friendlyName,
              friendly_name: res.data.friendlyName
            },
            account: {
              id: res.data.supplier.apiKey,
              name: res.data.supplier.name,
              supplierId: res.data.supplier.supplierId,
              platform: 'Supplier Core'
            }            
          })

          // LogRocket Identify
          LogRocket.identify(res.data.uuid, {
            name: res.data.friendlyName,
            email: res.data.username,              
            supplierId: res.data.supplier.supplierId,            
            supplierApiKey: res.data.supplier.apiKey,
            platform: 'Supplier Core'
          });

          // Sentry Initialization
          Sentry.setUser({ 
            id: res.data.uuid,
            email: res.data.username,
            username: res.data.username
          });

          Sentry.setTag("platform", "Supplier Core");          
          Sentry.setTag("supplierId", res.data.supplier.supplierId);
          Sentry.setTag("supplierName", res.data.supplier.name);
          Sentry.setTag("supplierApiKey", res.data.supplier.apiKey);                              
        } catch {
          console.error('Failed to initialize 3rd party integrations')
        }
        // LogRocket plugin
        try {
          LogRocket.identify(res.data.username, {
            name: res.data.username,
            email: res.data.username
          })
        } catch {
          console.error('Failed to initialize LogRocket')
        }
      }
    },
    () => {
      //
    }
  )

  return apiResponse
}
