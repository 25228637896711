// Global - Config
import { HTTPBase } from 'config'

/** Required to make pendo work in TypeScript */
declare global {
  interface Window {
    pendo: any
  }
}

export async function apiStatusCheck() {
  let apiResponse = {
    environment: 'None',
    wasSuccessful: false
  }

  await HTTPBase.get('api/status/get').then(
    (res) => {
      if (res.status === 200 && res.data) {
        apiResponse = res.data        
      }
    },
    () => {
      //
    }
  )

  return apiResponse
}
